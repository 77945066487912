.wizard {

  @include themify($themes) {
    background-color: themed('colorBackground');
  }

  .wizard__form {
    max-width: 610px;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 0;
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .wizard__toolbar {
      
    }
  }
}

.wizard__steps {
  display: flex;
  margin-bottom: 20px;
}

.wizard__step {
  width: 100%;
  text-align: center;
  height: 35px;
  text-transform: uppercase;
  display: flex;
  transition: background 0.3s;
  border-radius: 5px;

  @include themify($themes) {
    border: 1px solid themed('colorBorder');
    background: themed('colorHover');
  }

  p {
    font-weight: 700;
    margin: auto;
    font-size: 14px;
    transition: all 0.3s;
  }

  &.wizard__step--active {
    background: $color-blue;
    border-color: $color-blue;

    p {
      color: #ffffff;
    }
  }
}

.wizard__form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wizard__header {
  margin: 0 auto 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wizard__title {
  margin-left: auto;
  margin-right: auto;
  font-weight: 500;
  text-align: center;
  margin-bottom: 30px;
  color: #ddd;
}

.wizard__sub_title {
  text-transform: none;
  font-size: 12px;
  line-height: 18px;
  opacity: 0.7;
  margin-top: 3px;
}

.wizard__description {
  color: $color-additional;
  margin: 0;
  text-align: justify;
}

.wizard .card-body {
  padding: 0;
}

.squared-corner-theme {

  .wizard__step {
    border-radius: 0;
  }
}

.wizard__toggle {
  display: flex;
  flex-direction: column;
  width: 100%;

  .wizard__checkbox__group {
    display: flex;
    width: 100%;
    margin-top: 15px;
    justify-content: space-between;
  }

  .wizard__input {
    width: 100%;
    justify-content: space-between;
    display: flex;
    margin: 0;
    align-items: center;

    .wizard__description {
      white-space: nowrap;
      margin-right: 25px;
    }
  }

  .wizard__toggle__top {
    display: flex;
    align-items: flex-start;

    div.toggle-btn{
      width: unset;
    }

    span.form__form-group-label {
      width: 100%;
      white-space: unset;
      max-width: unset;
      font-weight: bold;
      font-size: 12px;
      margin: 0;
      max-height: unset;
    }
  }
  .form-radio{
    width: 14%;
  }
}