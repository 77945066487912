.catalog-items__wrap {
  overflow: hidden;
  width: 100%;
}

.catalog-items {
  width: calc(100% + 30px);
  display: flex;
  flex-wrap: wrap;
}

.catalog-item {
  min-width: 250px;
  max-width: 310px;
  height: auto;
  margin-bottom: 30px;
  @include directify($directions) {
    #{directed('margin-right')}: 30px;
  }
  position: relative;

  @include themify($themes) {
    background-color: themed('colorBackground');
  }
}

.catalog-item__rating {
  display: flex;
  padding: 7px 0 15px 0;
  margin: 0 auto;
  justify-content: center;
}

.catalog-item__link {
  padding-top: 25px;
  display: block;

  &:hover {
    text-decoration: none;

    .catalog-item__title {
      color: $color-accent;
    }
  }
}

.catalog-item__location {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #646777;
  margin-top: 3px;
  margin-left: -6px;

  svg {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: flex-start;
  }
}

.catalog-item__schedule {
  margin-left: -2px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  color: #646777;
  margin-top: 5px;

  & > div {
    white-space: nowrap;
    display: flex;
    align-items: center;

    svg {
      width: 20px;
      margin-right: 3px;
    }
  }
}


.catalog-item__no_singe_session {
  position: absolute;
  top: 5px;
  right: 10px;
  color: #646777;

  &:hover {
    cursor: pointer;
    color: #50e3c2;
  }
}

.catalog-item__days {
  margin-top: 5px;
  display: flex;
  justify-content: flex-start;

  font-size: 11px;

  .catalog-item__day {
    padding: 2px 10px;
    border-radius: 4px;
    margin-right: 5px;
    color: #646777;
    background-color: #cccccc;

    &.active {
      background-color: rgb(80, 227, 194);
      color: #ffffff;
    }
  }
}

.catalog-item__img-wrap {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.catalog-item__img {
  height: 100%;
  width: auto;
}

.catalog-item__info {
  min-height: 50px;
  @include directify($directions) {
    text-align: directed('left');
  }
  position: relative;
  width: calc(100% - 90px);
}

.catalog-item__title {
  font-weight: 500;
  transition: all 0.3s;
}

.catalog-item__description {
  margin: 0;
  color: $color-additional;
  line-height: 17px;
}

.catalog-item__price {
  position: absolute;
  top: 0;
  @include directify($directions) {
    #{directed('right')}: -90px;
  }
  line-height: 36px;
}

.catalog-item__old-price {
  position: absolute;
  @include directify($directions) {
    #{directed('right')}: -90px;
  }
  top: 30px;
  line-height: 28px;
  color: $color-additional;
  margin: 0;
}

.catalog-item__color {
  height: 10px;
  width: 10px;
  @include directify($directions) {
    #{directed('margin-right')}: 8px;
  }
  display: inline-block;
  border-radius: 50%;
  margin-top: 10px;
}

.catalog-item__label {
  position: absolute;
  top: -2px;
  @include directify($directions) {
    #{directed('right')}: 20px;
  }
  width: 50px;
}
