.addOperativeStepButton {
  background-color: #007dbc !important;
  border-radius: 6px !important;
  color: white !important;
  font-weight: 500 !important;
  font-size: 24px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 30px !important;
  height: 30px !important;
  margin: 0 !important;
  border: none;
}

.removeOperativeStepButton {
  background-color: #ff4444 !important;
  border-radius: 6px !important;
  color: white !important;
  font-weight: 500 !important;
  font-size: 24px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 30px !important;
  height: 30px !important;
  margin: 0 !important;
  border: none;
}

.cameraButton {
  background-color: transparent  !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 !important;
  border: none;
}
.commentButton {
  background-color: transparent  !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 !important;
  border: none;
}

.addOperativeStepButton:disabled {
  opacity: 0.5;
}

.actionButtonContainer {
  display: flex;
  flex-direction: row;
  width: 0px;
  margin-left: 20px;
}

.operativeStepActionButton {
  min-width: fit-content;
  margin-left: 20px;
}

.operativeStepsCheckBox {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 !important;
}

.th30 {
  border-bottom-color: transparent !important;
  width: 30px !important;
  min-width: 30px;
  max-width: 30px !important;
}

.th60 {
  border-bottom-color: transparent !important;
  width: 60px !important;
  min-width: 60px;
  max-width: 60px !important;
}

.th180 {
  border-bottom-color: transparent !important;
  width: 180px !important;
  min-width: 180px;
  max-width: 180px !important;
}

.th200 {
  border-bottom-color: transparent !important;
  width: 200px !important;
  min-width: 200px;
  max-width: 200px !important;
}

.td30 {
  width: 30px !important;
  min-width: 30px;
  max-width: 30px !important;
}

.td60 {
  width: 60px !important;
  min-width: 60px;
  max-width: 60px !important;
}

.td180 {
  width: 180px;
  min-width: 180px;
  max-width: 180px;
  line-height: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.td200 {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  line-height: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.td280 {
  width: 280px;
  min-width: 280px;
  max-width: 280px;
  line-height: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.th200 {
  border-bottom-color: transparent !important;
  width: 200px !important;
  min-width: 200px;
  max-width: 200px !important;
}


.th220 {
  border-bottom-color: transparent !important;
  width: 220px !important;
  min-width: 220px;
  max-width: 220px !important;
}

.th280 {
  border-bottom-color: transparent !important;
  width: 280px !important;
  min-width: 280px;
  max-width: 280px !important;
}


.td200 {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.th250 {
  border-bottom-color: transparent !important;
  width: 250px !important;
  min-width: 250px;
  max-width: 250px !important;
}

.td220 {
  width: 220px;
  min-width: 220px;
  max-width: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.td250 {
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.operativeStepJobDescriptionContainer {
  white-space: nowrap;
  max-width: 180px;
  overflow: hidden !important;
  text-overflow: ellipsis;
  color: black;
}

.operativeStepJobStepDescriptionContainer {
  white-space: nowrap;
  max-width: 240px;
  overflow: hidden !important;
  text-overflow: ellipsis;
  color: black;
}

.operativeStepJobDescriptionText {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s linear;
  white-space: nowrap;
}

.operativeStepJobDescriptionText:focus,
.operativeStepJobDescriptionText:hover {
  &:after {
    content: attr(data-text);
    overflow: visible;
    text-overflow: inherit;
    background: #95a4b1;
    position: absolute;
    left: auto;
    top: auto;
    width: auto;
    max-width: 20rem;
    padding: 5px;
    white-space: normal;
    word-wrap: break-word;
    display: block;
    color: black;
    margin-top: -1.25rem;
    z-index: 50;
    border-radius: 4px;
  }
}
