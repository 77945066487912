.container {

  @media screen and (min-width: 768px) {
    width: 100%;
    max-width: 1860px;
    margin: 0px;
  }
}

.container__wrap {
  @include directify($directions) {
    #{directed('padding-left')}: 0px;
  }
  min-height: 105vh;
  transition: padding-left 0.3s;

  @include themify($themes) {
    background: themed('colorBackgroundBody');
  }

  @media screen and (min-width: 576px) {
    padding-left: 100px !important;
    padding-right: 40px !important;
    padding-bottom: 40px !important;
  }
}

.container__wrap--collapse{
  @include directify($directions) {
    #{directed('padding-left')}: 0px;
  }
  min-height: 105vh;
  transition: padding-left 0.3s;

  @include themify($themes) {
    background: themed('colorBackgroundBody');
  }

  @media screen and (min-width: 576px) {
    padding-left: 240px !important;
    padding-right: 40px !important;
    padding-bottom: 40px !important;
  }
}

.layout {

  &.layout--collapse {

    & + .container__wrap {
      @include directify($directions) {
        #{directed('padding-left')}: 0px;
      }

      @media screen and (min-width: 576px) {
        @include directify($directions) {
          #{directed('padding-left')}: 90px;
        }
      }
    }
  }

  &.layout--top-navigation{

    & + .container__wrap{

      @media screen and (min-width: 576px) {
        @include directify($directions) {
          #{directed('padding-left')}: 250px;
        }
      }
    }
  }
}

.bread_crumbs_wrapper{
  margin: 80px 0 0 0;
  padding: 0px 0 0 240px;
  transition: padding ease .3s;
}

.bread_crumbs_wrapper--collapse{
  margin: 80px 0 0 0;
  padding: 0px 0 0 100px;
  transition: padding ease .3s;
}

.wrapper{
  zoom: 90% !important;
}