.watchlist-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 15px;

  .btn {
    background-color: transparent;

    &.active-watchlist {
      background-color: #e7e2e2;
    }
  }
}
