.account--not-photo{
  padding-top: 50px;
  text-align: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.carousel-indicators > li{
  background-color: #0fb3c7;
}


.logo-landing{
  width: 27%;
  height: 150px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  padding-top: 10px;

  .logo-icon1{
    width: 50%;
    margin-left: 3%;
    height: 90px;
  }
  .logo-icon2{
    width: 30%;
    height: 95px;
    color: black!important;
  }
}

.landing-page {
  font-family: 'Poppins', sans-serif;
  width: 100%;
  background: #ffffff;
  overflow-x: hidden;

  .logo{
    width: 35%;
    height: 170px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    padding-top: 10px;

    .logo-icon1{
      width: 50%;
      margin-left: 3%;
      height: 90px;
    }
    .logo-icon2{
      width: 30%;
      height: 95px;
      color: black!important;
    }
  }

  .container {
    transition: 0.3s;
  }



  .landing__header {
    padding-top: 50px;
    text-align: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 1250px;

    .box-header {
      display: flex;

      .header-title{
        display: flex;
        flex-flow: column;
        align-items: center;
        line-height: normal;
        margin-top: 200px;
        h2{
          width: 530px;
          font-size: 35px;
          color: #244153;
          margin-bottom: 20px;

        }
        .text1{
          font-size: 16px;
          width: 510px;
          text-align: left;
          font-weight: 600;
          color: #244153;
          text-indent: 25px;
           }
        span{
          font-size: 16px;
          width: 510px;
          font-weight: 600;
          color: #244153;
          text-align: justify;

        }
        .buttons{
          width: 500px;
          display: flex;
          justify-content: space-between;
          margin-top: 25px;
          .sign-in{
            padding: 16px 80px!important;
            border-radius: 15px;
            color: black;

          }
          .create-account{
            padding: 16px 50px!important;
            border-radius: 15px;
            color: black;

          }
        }
      }

      .img-box{
        position: relative;
        grid-area: auto;
        img{
          width: 100%;
          height: auto;
        }
        .macbook-video{
          position: absolute;
          right: 7%;
          top: 5%;
          width: 84%;
          height: 60%;
        }
      }
    }
  }

  .box-blog {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: -200px;

    .blog {
      width: 41%;

      a {
        width: 100%;
      }

      .landing__demo {
        .landing__demo-img-wrap {
          .card-blog {
            width: 100%;
            min-height: 365px;
            background: white;
            border-radius: 20px;
            box-shadow: 0 18px 30px 0 rgba(28, 50, 61, 0.14);

            h1 {
              font-size: 14px;
              font-weight: 600;
              text-align: left;
              color: black;
              line-height: 20px;
              padding: 30px 40px 0;

              p {
                font-weight: 600;
                color: black;
              }
            }

            .card-text {
              width: 88%;
              text-align: left;
              font-style: italic;

              p {
                padding: 20px 0 0 40px;
                color: black;
              }

              .text-img {
                display: flex;
                padding-top: -11px;

                p {

                }

                .img-logo {
                  width: 46%;
                  border: 6px solid #0bceee7a;
                  margin-left: 36px;
                }
              }

              .text-end {
                margin-top: -17px;
                padding-bottom: 20px;
              }
            }
          }
        }
      }
    }

    .blog1 {
      h2 {
        text-align: center;
        margin-bottom: 25px;
        color: #244153;
      }
    }

    .blog2 {
      display: flex;
      flex-flow: column;
      align-items: flex-end;
      margin-top: 140px;

      .landing__demo {
        .landing__demo-img-wrap {
          .card-blog2 {
            min-height: 375px;
          }
        }
      }

      .other_btn {
        width: 35%;
        margin-top: 25px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
      }
    }
  }
  .mobile-carousel{
    display: none;
  }

  .carousel-blog {
    margin-top: -125px;
      .landing__demo {
        background-color: white;
        margin: 40px;
        box-shadow: 0 18px 30px 0 rgba(28, 50, 61, 0.14);
        border-radius: 20px;
        width: 40%;
        height: 300px;
        cursor: pointer;
        overflow: hidden;
        padding-bottom: 15px;


        .landing__demo-img-wrap::before {
          border-radius: 20px;
          height: 300px;
        }

        .landing__demo-img-wrap::after {
          content: "read more";
          border-radius: 20px;
          height: 300px;

        }

        h3 {
          padding-top: 20px;
        }

        .card-text {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex-flow: column-reverse;
          padding: 5px 20px 0px 20px;
          overflow: hidden;
          height: 230px;

          p {
            text-align: justify;
            max-height: 230px;
            overflow: hidden;
          }

          img {
            width: 50%;
            border-radius: 5px;
            margin-bottom: 10px;
          }
        }
      }

  }

  @media screen and  (max-width: 1200px) {
    .logo{
      width: 40%;
    }
  .landing__header {
    padding-top: 200px;
    .box-header {
      flex-flow: column;
      align-items: center;

      .header-title{
        margin-top: 0;
      }

      .col-md-5{
        width: 100% !important;
      }
      .img-box{
        margin-top: 80px;

        img{
          width: 80%;
          transform: none;

        }
        .macbook-video {
          position: absolute;
          right: 16%;
          top: 5%;
          width: 67%;
          height: 60%;

        }
      }
    }
  }
    .box-blog{
      margin-top: 100px;
      flex-flow: column;

      .blog{
        width: 60%;
      }
    }
    .carousel-blog{
      margin-top: 150px;
    }
    .landing-footer{
      height: 770px;
      .text{
        padding: 480px 80px 0;

        p{
          line-height: 27px;
        }
      }
      .footer-logo{
        width: 60%;
        height: 82px;
      }
    }

}
  @media screen and (max-width: 992px) {
    .logo{
      width: 55%;
      height: 158px;

      .logo-icon2{
        height: 80px;
      }
    }
    .landing__header{
      height: 1200px;
    }

    .mobile-carousel{
      display: block;
      .landing__demo{
        width: 90%;
      }
    }
    .carousel-desktop{
      display: none;
    }
    .landing-footer{
      height: 800px;
      .text{
        padding: 480px 60px 0;

        p{
          line-height: 27px;
        }
      }
      .footer-logo{
        width: 60%;
        height: 68px;
      }
    }

  }


  @media screen and (max-width: 576px) {

    .logo{
      width: 70%;
      height: 125px;

      .logo-icon2{
        margin-top: 14px;
        height: 58px;
      }
    }
    .landing__header {
      height: 800px;

      .box-header {
        .header-title {
          h2 {
            width: 320px;
            font-size: 20px;
          }
           .text1{
             width: 320px;
             font-size: 14px;
             text-align: justify;
             text-indent: 0;
           }
          span {
            width: 320px;
            font-size: 14px;
          }
          .buttons{
            width: 340px;
            display: flex;
            flex-flow: column;
          }
        }
        .img-box{
          img{
            width: 100%;
          }
          .macbook-video {
            position: absolute;
            right: 7%;
            top: 5%;
            width: 84%;
          }
        }
      }
    }

    .mobile-carousel{
      margin-top: 250px;
    }

    .carousel-blog {
      .landing__demo {
        .card-text {
          img {
            width: 70%;
          }
        }
      }
    }

    .box-blog{
      margin-top: 220px;
      .blog{
        width: 85%;
        .card-blog{
          .text-img{
            flex-flow: column;
            align-items: center;
          }
          .img-logo{
            width: 60%;
          }
        }
      }
      .blog1{
        h2{
          font-size: 14px;
          margin-bottom: 15px;
        }
      }
      .blog2{
        margin-top: 60px;
        .other_btn{
          width: 55%;
        }
      }
    }
    .landing-footer{
      height: 735px;

      .text{
        padding: 390px 15px 0;
        font-size: 14px;
        text-align: center;

        p{
          line-height: 21px;
        }
      }
      .footer-logo{
        width: 70%;
        height: 45px;
      }
      .end{
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: 414px) {

    .landing__header {
      .box-header {
        .img-box{
          img{
            width: 100%;
          }
          .macbook-video {
            position: absolute;
            right: 7%;
            top: 5%;
            width: 84%;
          }
        }
      }
    }

    .carousel-blog {
      .landing__demo {
        .card-text {
          img {
            width: 70%;
          }
        }
      }
    }
}

  @media screen and (max-width: 320px) {

    .logo{
      width: 70%;
      height: 125px;

      .logo-icon2{
        width:40%;
      }
    }

    .carousel-blog {
      .landing__demo {
        .card-text {
          img {
            width: 70%;
          }
        }
      }
    }

    .landing__header {
      .box-header {
        .header-title {
          .text1 {
            width: 270px;
          }
          span{
            width: 270px;
          }
          .buttons{
            width: 270px;
            display: flex;
            flex-flow: column;
          }
        }
        .img-box{
          img{
            width: 100%;
          }
          .macbook-video {
            position: absolute;
            right: 7%;
            top: 5%;
            width: 84%;
          }
        }
      }
    }
  }

  }

.app-bar{
  background-color: #8ae8f7!important;
}
.policy-header-text{
  display: flex;
  justify-content: space-between;
}
.terms-body{
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  width: 50%;
  margin: 150px auto;
  text-align: justify;

  h1, h2{
    text-align: center;
    margin-top: 15px;
  }
  h3{
    margin-top: 15px;
  }
  p, em{
    text-indent: 35px;
  }
}
