.reusable-tooltip {
  position: relative;
  display: inline-block;
}

.reusable-tooltip .reusable-tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.reusable-tooltip:hover .reusable-tooltip-text {
  visibility: visible;
  opacity: 1;
}