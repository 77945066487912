.file-preview-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow-y : auto;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, .8);

  .file-preview-slider {
    max-width: 300px;
    width: 100%;
    position: fixed;
    bottom: 25px;
    left: 25px;
  }

  .loading-container {
    .loading {
      position: relative;
      margin-top: 45vh;

      &:before {
        background-color: transparent;
      }
    }
  }

  .file-preview-close {
    position: fixed;
    top: 15px;
    right: 30px;

    color: #dadada;

    &:hover {
      color: #ffffff;
      cursor: pointer;
    }
  }

  .file-preview-body {
    text-align: center;

    .pg-viewer-wrapper {
      overflow-y: unset;
    }

    .photo-viewer-container {
      width: 100% !important;
      height: fit-content !important;

      .photo {
        margin: 5vh auto;
        height: 90vh !important;
        width: auto !important;
      }
    }

    .document-container {
      margin: 5vh auto;
    }

    canvas {
      margin-top: 3vh;
      margin-bottom: 3vh;
    }

    .pdf-controlls-container {
      display: flex;
      position: fixed;
      bottom: 25px;
      left: 25px;

      .view-control {
        i {
          display: block;
          margin-right: 5px;
          width: 40px;
          height: 40px;
          border-radius: 5px;
          overflow: hidden;

          &:hover {
            cursor: pointer;
            background-color: rgba(0, 0, 0, 0.3);
          }

          &.zoom-in:before {
            content: "";
            display: block;
            background: url(../shared/img/zoom_in.svg) no-repeat;
            background-size: cover;
            width: 100%;
            height: 100%;
          }

          &.zoom-out:before {
            content: "";
            display: block;
            background: url(../shared/img/zoom_out.svg) no-repeat;
            background-size: cover;
            width: 100%;
            height: 100%;
          }

          &.zoom-reset:before {
            content: "";
            display: block;
            background: url(../shared/img/zoom_out_map.svg) no-repeat;
            background-size: cover;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
.error-file{
  color: red;
  font-size: 10px;
  padding-top: 4px;
}

.react-images__view{
  img{
    max-width: 50% !important;
  }
}
