.react-select {
  width: 200px;
  height: 46px;
  border-radius: 6px;
  border: 1px solid #eaeaea;
  font-size: 12px;
  box-shadow: none;
  background: transparent;
  &:hover {
    cursor: pointer !important;
  }
}

.react-select-full {
  width: 100%;
}

.react-select-error {
  width: 100%;
  height: 32px;
  font-size: 12px;
  outline: 1px solid #ffbcbc;
}

.react-select__control {
  border-radius: 0 !important;
  transition: all 0.3s;
  height: 46px;
  background-color: transparent !important;
  border: none !important;

  &:hover {
    cursor: pointer !important;
  }

  &.react-select__control--is-focused,
  &:hover {
    box-shadow: none;
    background: transparent;
    border: none !important;
  }

  &.react-select__control--is-disabled {
    background-color: #f2f4f7;
    .react-select__value-container {
      .react-select__placeholder {
        color: #d4d4d4 !important;
      }
    }
  }
}

.react-select__input {
  height: 30px;
  input {
    caret-color: transparent;
    height: 100% !important;
  }
}

.react-select__indicator-separator {
  display: none;
}

.react-select__dropdown-indicator,
.react-select__clear-indicator {
  cursor: pointer;

  @include themify($themes) {
    color: themed("colorIcon");
  }

  svg {
    height: 16px;
    width: 16px;
  }
}

.react-select__multi-value {
  background-color: transparent;
  border: 1px solid $color-blue;

  .react-select__multi-value__label {
    @include directify($directions) {
      #{directed('border-right')}: 1px solid $color-blue;
    }
    padding: 3px 6px;

    @include themify($themes) {
      color: themed("colorText");
    }
  }
}

.react-select__multi-value__remove {
  opacity: 0.8;
  transition: 0.3s;
  cursor: pointer;

  @include themify($themes) {
    color: themed("colorText");
  }
}

.react-select__multi-value__label,
.react-select__multi-value__remove {
  @include themify($themes) {
    background: themed("colorBackground");
  }
}

.react-select__single-value {
  color: #333e48;
}

.react-select__menu {
  box-shadow: none !important;
  margin-top: 6px;
  margin-bottom: 6px;
}

.react-select__menu-list {
  top: calc(100% + 1px);
  border-radius: 0;
  box-shadow: none;
  font-size: 12px;
  overflow: hidden;

  @include themify($themes) {
    background: themed("colorBackground");
    border: 1px solid themed("colorFieldsBorder");
  }
}

.react-select__option {
  transition: all 0.3s;
  border-radius: 0;
  display: flex;
  cursor: pointer !important;
  padding: 8px 10px;

  @include themify($themes) {
    background: themed("colorBackground");
    color: themed("colorText");
  }
}

.react-select__color {
  display: block;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  @include directify($directions) {
    #{directed('margin-right')}: 0px;
    #{directed('margin-left')}: 5px;
  }
  margin-top: auto;
  margin-bottom: auto;
}

.form__select-color {
  display: flex;
  flex-wrap: wrap;
  max-width: 84px;
  @include directify($directions) {
    #{directed('margin-right')}: 32px;
  }
}

.react-select__value-container {
  height: 32px;
  &:focus {
    border: none !important;
    outline: none !important;
  }
}
.react-select__indicators {
  height: 46px;
}
.react-select__control {
  min-height: 46px !important;
}

.css-1g6gooi {
  position: absolute !important;
}
