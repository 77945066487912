.field-tags-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.field-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 7px;
  border: 1px solid #e7e2e2;
  border-radius: 5px;
  color: #646777;
  font-size: 12px;
  margin: 2px;
  user-select: none;

  :hover {
    cursor: pointer;
  }

  &.tag-active {
    background-color: #4ce1b6;
    border-color: #4ce1b6;
    color: white;
  }

  span {
    max-width: 200px;
  }
}
