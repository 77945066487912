.upcoming-job-row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    width: 24px;
    height: 24px;
  }

  .title-wrapper {
    display: flex;
    flex-direction: column;

    .job-title {
      text-align: center;
    }

    .job-rating {
      display: flex;
      margin-top: 10px;
      justify-content: center;

      svg {
        display: block;
        width: 24px;
        top: 0;
        height: 24px;
        position: static;
      }
    }

  }

  .job-date {
    display: flex;
    align-items: center;
    flex-grow: 1;

    svg {
      width: 24px;
      height: 24px;
    }

    span {
      margin-left: 10px;
    }
  }

  .job-time {
    display: flex;
    align-items: center;
    flex-grow: 1;

    svg {
      width: 24px;
      height: 24px;
    }

    span {
      margin-left: 10px;
    }
  }

  .job-multiple-sessions {
    display: flex;
    align-items: center;
    flex-grow: 3;
  }

  .job-rate {
    display: flex;
    align-items: center;
    flex-grow: 1;

    svg {
      width: 24px;
      height: 24px;
    }

    span {
      margin-left: 10px;
    }
  }

  .job-location {
    display: flex;
    align-items: center;
    flex-grow: 1;

    svg {
      width: 24px;
      height: 24px;
      min-width: 24px;
    }

    span {
      margin-left: 10px;
    }
  }

  .job-button-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    label{
      margin-bottom: 0;
    }

    .next {
      margin: 0;
    }
  }
}


.session-info-item {
  position: relative;
  background: #fafbfe;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 15px;

  .session-checkbox {
    position: absolute;
    right: 10px;
    top: 15px;
  }

  .title {
    font-weight: bold;
  }

  .content-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 30px 0 20px;

    .session-item {
      display: flex;
      align-items: center;

      span {
        margin-left: 10px;
      }
    }

    .time-location {
      margin-bottom: 15px;
      margin-right: 25px;
    }

    .nurse-details {
      display: flex;
      flex-wrap: wrap;
      flex: 3;
      align-items: flex-start;
      align-content: flex-start;
      justify-content: flex-start;
      max-width: 850px;
      .session-item {
        width: 280px;
        margin: 5px 0;
      }
    }

    .details-session {
      margin-bottom: 15px;
    }

    .requirements {
      min-width: 250px;
      min-height: 10px;

      h5 {
        font-weight: bold;
      }
    }
  }
}

.watchlist-container {
  width: 270px;
  min-width: 270px;
  box-sizing: border-box;
  flex-grow: 0;
}

.upcoming-container {
  flex-grow: 1;
}

@media (max-width: 768px) {
  .watchlist-container {
    width: 100%;
    min-width: 100%;
  }
}

@media (max-width: 1200px) {
  .title-wrapper {
    margin-bottom: 20px;
  }
  .job-button-action {
    margin-top: 20px;

  }
}
