.container{

  .card-help{
    min-height: 70vh;
    .help-title{
      .form__form-group{
        margin: 20px 0;
      }
      .box-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 55px;
        width: 100%;
        background-color: #f7f7f7;
        margin-bottom: 5px;
        border-radius: 5px;
        color: #646777;
      }
      .box-btn:hover{
        background-color: #e6e5e5;
      }
    }
  }
  .card-video {
    height: 50vh;
    padding: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;

      iframe {
        width: 100%;
        height: 70vh;
        border-radius: 5px;
      }
    }
  }
