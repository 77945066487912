.bread_crumbs-active{
    color: #007DBC !important;
    &:hover{
        cursor: pointer;
        text-decoration: underline;
    }
}

.bread_crumbs-not_active{
    color: #333E48 !important;
    &:hover{
        cursor: pointer;
        text-decoration: underline;
    }
}