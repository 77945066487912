.job_collapse__wrapper {

  .done{
     color: #4ce1b6;
  }
  .cross{
    color: #ff4861;
  }

  @include directify($directions) {
    text-align: directed('left');
  }
  &.opened, &.opening {

    .collapse__title {

      p {
        color: $color-additional;
      }


    }

  }

  &.boxed {
    border: solid 1px #dddddd;
    margin-top: -1px;

    .collapse__title {
      background-color: #ffffff;
      border-bottom: solid 1px #dddddd;
      padding: 8px 15px;

      p {
        color: #444444;
      }


    }

    .collapse__content {
      @include directify($directions) {
        #{directed('padding-right')}: 20px;
        #{directed('padding-left')}: 15px;
      }
      padding-top: 16px;
      padding-bottom: 20px;
    }

    &.closed {

      .collapse__title {
        border-bottom: none;
      }
    }
  }

  &.with-shadow {
    margin-bottom: 10px;
    box-shadow: none;

    .collapse__title {
      background: #ffffff;
    }

    .collapse__content {
      @include directify($directions) {
        #{directed('padding-right')}: 20px;
        #{directed('padding-left')}: 25px;
      }
      padding-top: 15px;
      background: #ffffff;
      padding-bottom: 1px;

      @include themify($themes) {
        color: themed('colorText');
      }
    }

    .collapse__title {
      padding: 15px 25px;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.05);
      transition: background-color 0.3s;

      @include themify($themes) {
        color: themed('colorText');
      }
      svg {
        position: static;
      }
      &:hover {

        @include themify($themes) {
          background-color: themed('colorHover');
        }
      }



      p {

        //svg {
        //  display: block;
        //  top: calc(50% - 7px);
        //  transition: all 0.3s;
        //
        //  @include themify($themes) {
        //    fill: themed('colorText');
        //  }
        //}
      }
    }

    &.opened .collapse__title,
    &.opening .collapse__title {

      p {
        color: inherit;
      }

      //svg {
      //  fill: inherit;
      //  transform: rotate(180deg);
      //}
    }
  }

  &.closing {

    .collapse__content {
      padding-top: 0;
      padding-bottom: 0;
      transition: all 0.3s;
    }
  }
}

.collapse__title, .collapse__content {
  @include directify($directions) {
    #{directed('padding-left')}: 20px;
    #{directed('padding-right')}: 15px;
  }
}

.collapse__title {
  padding-bottom: 10px;
  padding-top: 6px;
  cursor: pointer;
  position: relative;
  background: transparent;
  display: block;
  width: 100%;
  border: none;
  @include directify($directions) {
    text-align: directed('left');
  }

  p {
    font-weight: 700;
    margin-top: 0;
    transition: all 0.3s;
    position: relative;

    //svg {
    //  @include directify($directions) {
    //    #{directed('right')}: 0px;
    //    #{directed('left')}: auto;
    //  }
    //  display: none;
    //}
  }

  //svg {
  //  position: absolute;
  //  @include directify($directions) {
  //    #{directed('left')}: 0px;
  //  }
  //  width: 14px;
  //  height: 14px;
  //  top: 8px;
  //  transition: all 0.3s;
  //
  //  @include themify($themes) {
  //    fill: themed('colorText');
  //  }
  //
  //}
}

.collapse__content {
  padding-bottom: 14px;
}


.read-more__button {
  display: inline;
  background-color: transparent;
  outline: none;
  border: 0;
  padding: 0;
  margin: 0;
  font-size: 12px;
  cursor: pointer;
  color: #007f8c;
}

.read-more__button:hover {
  text-decoration: underline;
}

.read-more__text--hide {
  max-height: 0;
  font-size: 0;
  opacity: 0;
}

.read-more__text--show {
  max-height: 10em;
  opacity: 1;
  font-size: inherit;
}

.read-more__text--remaining {
  transition: opacity 240ms ease;
}
.text-id{
  text-transform: none;
}