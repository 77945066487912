.blog{
  .table-post{

    .title{
      width: 17%;
    }
    .description{
      width: 60%;
    }
    .published{
      padding-left: 50px;
    }
    .published1{
      padding-left: 60px;
    }
    .icon-edit:hover{
      color: #4ce1b6;
      cursor: pointer;
    }
    .icon-delete:hover{
      color: #4ce1b6;
      cursor: pointer;

    }
  }
}




.new-post{

  .box-blog {
    margin-left: 37px;
    .box-drop {
      width: 30%;

      .card__title {
        margin-bottom: -5px;
      }
      .drop-zone{
        position: relative;
        .upload-image-dropzone {
          position: inherit;
          height: 200px;

          .upload-image-dropzone-here {
            width: 80%;
            min-height: 200px;
            border-radius: 5px;

            svg {
              margin-left: 5px;
              margin-bottom: 5px;
            }
          }
        }
        .p-4{
          position: absolute;
          top: -8px;
          left: -3%;
          width: 88%;
        }
      }
      .form__form-group-input-wrap{
        width: 80%;
      }
    }
  }
  .box-text{
    width: 69%;

    textarea{
      min-height: 125px;
    }
  }

  .btn-toolbar{
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
// Post

.post-container {
  margin-top: 20px;
  padding-bottom: 50px;
  .back-arrow {
    margin-left: 50px;
    font-size: 28px;
    color: #646777;
  }

  .post-box {
    width: 50%;
    margin: 50px auto;
    display: flex;
    flex-flow: column;
    align-items: center;

    h2 {
      margin-bottom: 40px;
    }

    p {
      font-size: 14px;
      margin-bottom: 20px;
    }

    img {
      width: 100%;
    }

    .nl-container {
      background-color: #f2f4f7 !important;
    }
  }
}
@media screen and  (max-width: 1200px) {
  .post-container {
    .post-box{
      width: 65%;
      text-align: justify;
    }

  }
}

@media screen and (max-width: 576px) {
  .post-container {
    .back-arrow{
      font-size: 18px;
    }
    .post-box{
      width: 80%;
      h2{
        font-size: 22px;
      }
    }

  }
}