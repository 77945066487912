main {
  padding: 0;
}

body {
  margin: 0;
  padding: 0 !important;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  line-height: 1.6;
}

* {
  box-sizing: inherit;
}

ul, ol {
  // doesn't do RTL, it break a sidebar
  padding-left: 15px;
  margin-bottom: 0;
  list-style-type: disc;
}

a {
  color: $color-blue;
  transition: all 0.3s;

  &:hover {
    text-decoration: none;
    color: $color-blue-hover;
  }
}

.table-link{
  text-decoration: none;
  color:black ;
  display: block;
  width: 100%;
}

img {
  width: 100%;
}

.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  @include directify($directions) {
    #{directed('left')}: 0px;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    top: 0;
    @include directify($directions) {
      #{directed('left')}: 0px;
    }
    border-radius: 5px;

    @include themify($themes) {
      background-color: themed('colorBackground');
      opacity: 0.8;
    }
  }

  svg {
    fill: $color-accent;
    position: absolute;
    animation: refresh 1s infinite linear;
    z-index: 10;
    width: 48px;
    height: 48px;
    top: calc(50% - 24px);
    @include directify($directions) {
      #{directed('left')}: calc(50% - 24px);
    }
  }

  @keyframes refresh {

    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
