.quality-surveyor-image{
  min-width: 100px;
  height: 100px;
  .name-picture{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #53629a;
    border-radius: 50%;
    span {
      text-transform: uppercase;
      color: #ffffff;
    }
  }
}


.bg-light-gray{
  background-color: #EDF3F8;
}

.border-radius-full {
  border-radius: 50%;
}

.budget-element {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.instructions-status-dropdown {
  width:175px;
  height:46px;
  border:0;
}

.vo-print-top-info {
  display:flex;
  justify-content:space-between;
}

.amounts-info {
  flex-basis: 60%;
}

.flex-basis-10 {
  flex-basis: 10%;
}

.flex-basis-20 {
  flex-basis: 20%;
}

.flex-basis-30 {
  flex-basis: 30%;
}

.flex-basis-40 {
  flex-basis: 40%;
}

.flex-basis-50 {
  flex-basis: 50%;
}

.flex-basis-60 {
  flex-basis: 60%;
}

.flex-basis-70 {
  flex-basis: 70%;
}

.flex-basis-80 {
  flex-basis: 80%;
}

.flex-basis-90 {
  flex-basis: 90%;
}

.flex-basis-100 {
  flex-basis: 100%;
}