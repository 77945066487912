.card {
  width: 100%;
  // padding-bottom: 30px;
  height: 100%;
  border: none;
  background-color: transparent;

  &.card--not-full-height {
    height: 48px !important;
    border: 1px solid #dddddd;
  }
}

.card-body {
  height: 100%;
  border-radius: 5px;

  @include themify($themes) {
    background-color: themed('colorBackground');
  }
}

.card__title {
  min-height: 30px;
  @include directify($directions) {
    text-align: directed('left');
  }

  h5.card__title-center {
    text-align: center;
  }

  .table {
    width: unset;
    position: absolute;
    right: 0;
    top: 0;
  }

  margin-bottom: 15px;
  // text-transform: uppercase;
  position: relative;

  &:not(:first-child) {
    margin-top: 40px;
  }

  .subhead {
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.7;
    margin-top: 3px;
  }

  * {
    margin-bottom: 0;
  }

  h5 {
    font-size: 14px;
    margin-right: 10px;
  }
}

.squared-corner-theme {

  .card-body {
    border-radius: 0;
  }
}

.blocks-with-shadow-theme {

  .card-body {
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
  }
}

.question_job_wrapper{
  height: 100%; 
  // border-left: 4px solid #95A4B1;
  padding-left: 10px;
}

.img_question_wrapper{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  img{
    width: 45px;
    height: 45px;
    margin-right: 23px;
    margin-bottom: 7px;
    border-radius: 5px;
    &:hover{
      cursor: pointer;
    }
  }
  .get_more_img{
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    position: relative;
    &:hover{
      cursor: pointer;
    }
    img{
      width: 45px;
      height: 45px;
      position: absolute;
      top: 0;
      left: 0;
      filter: contrast(20%)
    }
    p{
      position: absolute;
      top: 25%;
      left: 25%;
      margin: 0;
      font-weight: 600;
      font-size: 16px;
      color: #FFFFFF;
      transform: translate(20%, 0%);
    }
  }
}

.img_question_wrapper-collapse{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  img{
    width: 45px;
    height: 45px;
    margin-right: 23px;
    margin-bottom: 7px;
    border-radius: 5px;
    &:hover{
      cursor: pointer;
    }
  }
}

.description_question_wrapper{
  background: #F4F4F4;
  padding: 10px;
  border-radius: 4px;
  margin-right: 15px;
  min-height: 90px;
}

.plan_wrapper{
  padding: 10px;
  background: #EDF3F8;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}