.table--bordered {
  .preview {

    margin-left: 40px;
    color: #3FA8FF;
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    svg {
      margin-right: 5px;
      width: 21px;
      height: 21px;
      g {
        opacity: 1;
        path {
          fill: #3FA8FF;
        }
      }
    }
  }
}
.edit-icon:hover{
  color: #3FA8FF;
}
.span-file{
  position: absolute;
  left: 133px;
  top: 31px;
}
.radio__group{
  white-space: nowrap;
  margin-right: 50px;
}
.title-preview{
  width: 58%;
  display: flex;
  justify-content: space-between;
}
.total-users{
  font-size: 17px;
  margin-bottom: 20px!important;
  display: flex;
  flex-flow: column;
  span{
    font-weight: bold;
  }
}

.download{
  border: none;
  background: transparent;
  cursor: pointer;
  color: #646777;

  svg{
    margin:0 5px 0 -25px;
  }
}
.table-responsive{
overflow-x: auto;
}

.hover{
  &:hover{
    cursor: pointer;
  }
}

.remove-pointer {
  cursor:default;
}

.hover-p{
  &:hover{
    color: #646777;
    cursor: pointer;
  }
}
.td-project{
  width: 30%!important;
}
.vo-row-td{
  border-bottom-color: transparent !important;
  width: 180px !important;
  min-width: 180px;
  max-width: 180px !important;
}
.vo-row-td-description{
  border-bottom-color: transparent !important;
  width: 280px !important;
  min-width: 280px;
  max-width: 280px !important;
}
.td-expand-project-icon{
  margin-inline:10px;
  padding-inline:20px;
  &:hover{
    background-color: #dedfe7;
    cursor: pointer;
  }
}
