.unassign-row{
  &:hover .unassign-button{
    display:inline-block;
    opacity: 1;
    transition: opacity 0.3s;
  }

}
.unassign-column {
  width:88px;

  &:hover {
    transform: scale(1.1);
    transition: transform 0.3s;
  }
}
.unassign-button-container {
  display:flex;
  flex-direction: row-reverse;
  height:40px;
  padding:0;
  margin:0;
}

.unassign-button{
  background-color: rgba(240, 0, 0, 1);
  margin:0;
  width: 100%;
  display:none;
  opacity:0;
  transition: opacity 0.3s;
  border-radius: 0 10px 10px 0;
  border:0;
  box-shadow: 0 3px 3px 0px rgba(0,0,0,0.3);
}

.unassign-button:hover{
  background-color: rgba(220, 0, 0, 1);;
  transition: background-color 0.3s;
}
