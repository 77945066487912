.innerContainer {
  background-color: white;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 75px;
  padding-right: 75px;
  border-radius: 6px;
}

.innerContainerSecond {
  background-color: white;
  padding-top: 50px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 6px;
}

.innerContainerSecondBottom {
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 6px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
}

.editButton {
  background-color: #edf3f8 !important;
  color: #007dbc !important;
  border-color: #edf3f8 !important;
  line-height: 1;
  margin: 0;
  height: 30px;
  font-size: 14px;
  width: min-content;
  white-space: nowrap;
}

.editButton:hover,
.cancelButton:hover {
  background-color: #edf3f8 !important;
  opacity: 0.8;
}

.editButton::before,
.cancelButton::before {
  background-color: #edf3f8 !important;
}

.deleteButton {
  background-color: #ff4444 !important;
  color: white !important;
  border-color: #ff4444 !important;
  line-height: 1;
  margin: 0;
  height: 30px;
  font-size: 14px;
  width: min-content;
  white-space: nowrap;
}

.deleteButton:hover {
  background-color: #ff4444 !important;
  opacity: 0.8;
}

.deleteButton:before {
  background-color: #ff4444 !important;
}

.cancelButton {
  background-color: #edf3f8 !important;
  color: #333e48 !important;
  border-color: #edf3f8 !important;
  line-height: 1;
}

.button {
  background-color: #007dbc !important;
  color: white !important;
  border-color: #007dbc !important;
  line-height: 1;
}

.button:hover {
  background-color: #007dbc !important;
  opacity: 0.8;
}

.button:disabled {
  color: rgba(51, 62, 72, 0.5) !important;
  background-color: #ccd6e0 !important;
  border-color: #ccd6e0 !important;
}

.button::before,
.addFlatButton::before {
  background-color: #007dbc !important;
}

.addButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.modal-header {
  width: 100%;
  background-color: #333e48;
  color: white;
}

.createBlockModal {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
}

.createBlockModal > .modal-content,
.createJobModal > .modal-content {
  padding: 0px 0px 0px 0px !important;
  width: 60%;
}

.createBlockModalBody,
.createJobModalBody {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 50px;
  padding-right: 50px;
}

.letterContainer {
  color: #007dbc;
  font-weight: bolder;
  border: 1px solid #007dbc;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(220, 230, 238, 0.4);
  width: 40px;
  height: 40px;
}

.centeredText {
  text-align: center;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.colorCircle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.breadCrumbsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.projectNameContainer {
  font-size: 16px;
  font-weight: 500;
  color: #007dbc;
}

.breadCrumb {
  font-size: 16px;
  cursor: pointer;
}

.breadCrumb > svg {
  margin-bottom: 0px;
}

.breadCrumbName:hover {
  text-decoration: underline;
}

.blockLetterContainer {
  background-color: white;
  width: min-content;
  white-space: nowrap;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
}

.blockLetter {
  color: #007dbc;
  font-weight: bolder;
}

.levelsContainer {
  margin-left: 4%;
}

.levelContainer,
.blockContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border-radius: 6px;
  margin-top: 10px;
  white-space: nowrap;
  padding: 8px;
  font-size: 16px;
  width: 40%;
  min-width: 250px;
  position: relative;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
}

.blockContainer {
  width: 15%;
  min-width: fit-content;
}

.levelLetterContainer {
  color: #007dbc;
  background-color: rgba(220, 230, 238, 0.4);
  border-radius: 5px;
  width: 30px;
  height: 30px;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  padding: 20px;
}

.levelEditIcon {
  display: flex;
  position: absolute;
  right: 8px;
  cursor: pointer;
}

.miniTextField {
  position: absolute;
  left: 5px;
}

.flatLimit {
  position: absolute;
  right: 8px;
  font-weight: bolder;
  font-style: italic;
}

.flatLevel {
  width: 170px !important;
  min-width: 30px !important;
}

.flatContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 4px 6px rgba(58, 57, 57, 0.06);
  white-space: nowrap;
  padding: 14px;
  font-size: 16px;
  position: relative;
  margin-right: 10px;
  height: 85%;
  width: 80px;
  margin-top: 10px;
}

.flatRowContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.addFlatButtonContainer {
  max-width: none !important;
}

.addFlatButton {
  background-color: #007dbc !important;
  color: white !important;
  border-color: #007dbc !important;
  line-height: 1;
  width: 40px !important;
  height: 40px !important;
  font-weight: bolder;
  font-size: 24px;
  margin-bottom: 0;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.flatOptionsIcon {
  position: absolute;
  right: 8px;
  height: min-content;
  cursor: pointer;
}

.typeSelect {
  width: 300px;
  border-radius: 6px;
}

.typeColor {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 10px;
}

.typeOption {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
}

.createJobModal {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
}

.createJobModal > .modal-content {
  width: 100%;
  /* height: 1000px; */
}

.selectContainer > div {
  width: 100%;
}

.selectContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.selectLabel {
  margin-bottom: 10px;
  color: #95a4b1;
}

.createStepsContainer {
  box-shadow: 0px 4px 6px rgba(58, 57, 57, 0.06);
  border-radius: 6px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.createStepsButton {
  margin-bottom: 0px;
}

.step {
  padding: 5px;
  margin-top: 5px;
}

.jobTableContainer {
  padding: 15px;
}

.addCostTypeButton {
  padding: 0px !important;
  .button {
    margin: 0;
  }
}

.allocateBudgetContainer {
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 6px;
  min-width: fit-content !important;
  position: relative;
}

.allocateButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.allocateButton > button {
  margin: 0;
}

.sp16 {
  font-size: 16px;
  font-weight: 400;
}

.costTypeDeleteButton {
  background-color: #ff4444;
  width: 22px;
  height: 22px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.costTypeContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // width: 40%;
}

.costTypeDropDown {
  margin-left: 8px;
  cursor: pointer;
}
