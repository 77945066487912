.modal_invoice {
  .modal_body__header {
    background-color: #edf3f8;
  }
  .modal-content {
    padding: 0 !important;
  }
  @media print {
    .modal-content {
      transform: scale(1.55);
      margin-top:50%;
    }
  }
  .modal__body {
    padding: 0 30px;
  }

  .modal-info {
    width: 28%;
  }
  .person-info {
    span {
      white-space: nowrap;
    }
  }
  .name,
  .date-title,
  .bill-title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    color: #007dbc;
  }

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #333e48;
  }

  .title {
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #95a4b1;
    }
  }

  .date {
    margin-top: 16px;
  }

  .bill {
    margin-right: 60px;
  }

  .job-description {
    padding: 20px;

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-transform: uppercase;
      color: #007dbc;
    }
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: #e8e8e8;
    break-after:always;
  }

  .description {
    text-align: left;
  }

  .jobs {
    .jobs-body {
      margin: 10px 0;
      padding: 10px 20px;

      p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: #000000;

        span {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #95a4b1;
        }
      }

      b {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        text-align: right;
        color: #000000;
      }
    }

    .taxBody {
      background-color: #f6f6f6;
      margin: 10px 0;
      padding: 10px 20px;
      border-radius: 4px;
    }

    .additional-work {
      padding: 15px 0 10px 0;
      width: 100%;
      background: #f7f7f7;
      margin-bottom: 20px;
      border-radius: 4px;

      .additional-work-body {
        margin: 10px 0;
      }

      .work {
        padding: 3px 20px;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: #000000;

        span {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #95a4b1;
        }
      }
    }
  }

  .box-subtotal {
    padding: 10px 20px;
    break-inside: avoid;
    break-before: always;
    .subtotal,
    .vat {
      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-transform: uppercase;
        color: #007dbc;
      }
      span {
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        text-align: right;
        color: #000000;
      }
    }
    .vat {
      margin-top: 7px;
    }
  }
  .modal__footer {
    color: white;
    padding: 20px 50px;
    background: #333e48;
    width: 100% !important;
    height: 60px !important;
    border-radius: 0 0 4px 4px;
    span {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      text-transform: uppercase;
      color: #ffffff;
    }
  }
}
