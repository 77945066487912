.selfie-wrapper {
  position: absolute;
  bottom: -5%;
  right: -5%;
  outline: none;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  z-index: 1;

  .camera-icon {
    margin-bottom: -3px;
    margin-right: -4px;

    &:hover {
      color: #4ce1b6;
      cursor: pointer;
    }
  }
}


.selfie-guide {
  .guide {
    position: absolute;
    top: 80px;
    right: 0;
    left: 0;
    margin: auto;
    width: 65%;
    height: 60%;
    border: 1.5px dashed rgba(255, 255, 255, .5);
    border-radius: 30px;
  }


  .hint {
    position: absolute;
    left: 0;
    right: 0;
    font-size: 15px;
    font-weight: 300;
    bottom: 90px;
    text-align: center;
    max-width: 230px;
    line-height: 1.4;
    color: white;
    margin: 0 auto;
  }


  .joruch {
    font-size: 24px;
    font-weight: 300;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 30px;
    text-align: center;
    z-index: 1;
    margin: 0px auto;
    color: white;
  }
}

@media screen and (max-width: 576px) {
  .selfie-guide {
    .webCam{
      width:100%;
    }

    .hint {
      bottom: 100px;
    }
  }

}