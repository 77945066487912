.contract-wrapper {

  .contract-modal {
    width: 100vw;
    height: 100vh;
    position: fixed;
    overflow-y: auto;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, .8);
    display: flex;

    &:hover {
      cursor: pointer;
    }

    .contract-modal-close {
      position: fixed;
      top: 15px;
      right: 30px;

      color: #dadada;

      &:hover {
        color: #ffffff;
        cursor: pointer;
      }
    }

    .contract-modal-printer {
      position: fixed;
      top: 15px;
      right: 60px;

      color: #dadada;

      &:hover {
        color: #ffffff;
        cursor: pointer;
      }
    }

    .contract-content-wrapper {
      margin: 0 auto;
    }

    .contract-content {
      display: block;
      width: 100%;
      height: fit-content;
      max-width: 1000px;
      padding: 40px;
      background-color: #fff;
      margin: 15px auto;


      .contract-table {
        margin-top: 20px;
        width: 100%;

        &.sign-table {
          border-spacing: 7px;
          border-collapse: inherit;
        }
      }

      .contract-form {
        .contract-form-item {
          font-weight: bold;

          span {
            color: #646777;
            font-weight: 500;
          }
        }

        .contract-terms-conditions {
          margin-top: 15px;
          text-transform: none;

          h6, h4 {
            font-weight: bold;
            margin-bottom: 10px;
            margin-top: 15px;
            color: #212529;
          }

          p {
            margin-bottom: 5px;
          }

          li, p, h6 {
            font-size: 13px;
            color: #212529;
            text-align: justify;
          }

          ol {
            list-style-image: none;
          }
        }

        .contract-footer {
          width: 100%;
          text-align: center;
          color: #b4b4b4;
          padding: 25px 0;
          text-transform: none;
        }

        .part {
          width: 100%;
          text-align: center;
          color: #212529;
          margin: 15px 0;
          font-weight: bold;
          text-transform: none;
        }

        h1, h2, h3, h4 {
          text-align: center;
          text-transform: none;
          margin-bottom: 15px;
          margin-top: 15px;
        }

        div.short-field {
          margin-top: 3px;
          display: flex;
        }

        ol {
          margin: 15px 0;

          li {
            color: #646777;
            font-size: 14px;
            text-align: justify;
            text-transform: none;
          }
        }

        p {
          font-size: 14px;
          text-align: justify;
          text-transform: none;
          text-indent: 14px;
        }

        td {
          text-transform: none;
          font-size: 14px;

          &.contract-table-label {
            height: 46px;
            font-weight: bold;
            padding-right: 5px;
            width: 170px;

            &:last-of-type {
              padding-left: 15px;
            }
          }

          &.contract-table-stamp {
            padding: 10px;
            min-width: 150px;
            min-height: 60px;
            border: 1px dashed #646777;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 80%;
              height: auto;
            }

            .sign-button {
              font-size: 16px;
              display: flex;
              align-items: center;

              &:hover {
                color: #4ce1b6;
                cursor: pointer;
              }
            }
          }

          &.contract-table-sign {
            padding: 10px;
            min-width: 150px;
            height: 60px;
            border: 1px dashed #646777;
            display: flex;
            justify-content: center;
            align-items: center;

            .sign {
              font-family: 'Beth Ellen', cursive;
              font-size: 20px;
              position: absolute;
              transform: rotate(-5deg);
            }

            .sign-button {
              font-size: 16px;
              display: flex;
              align-items: center;

              &:hover {
                color: #4ce1b6;
                cursor: pointer;
              }
            }
          }

          &.contract-table-value {
            min-height: 46px;

            .contract-table-label {
              color: #646777;
            }

            padding: 10px;
            border: 1px dashed #646777;

            .wizard__description {
              text-indent: unset;
            }

            .checkbox-btn__label {
              padding-top: 0;
            }
          }
        }
      }

      &:hover {
        cursor: default;
      }
    }
  }
}

.react-code-input {
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.contract-button {
  padding: 0;
  width: fit-content;
  margin: 0;
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }

  &:hover {
    color: #4ce1b6;
    cursor: pointer;
  }
}
