.job-information-table {
  width: 100%;
  margin-bottom: 15px;

  .job-information-line {
    width: 100%;
    border-bottom: 1px solid #eff1f5;
  }

  .job-field {
    padding: 5px 0;

    .job-field-label {
      font-size: 14px;
      color: #646777;
      font-weight: 400;
      line-height: unset;
      margin: 0;
    }

    .job-field-description {
      font-size: 12px;
      color: #999999;
      line-height: 14px;
      text-align: justify;
      width: 90%;
      margin: 0;
    }
  }

  .job-field-value {
    font-size: 14px;
    text-align: right;
    color: #646777;
    font-weight: 500;

    .checkbox-btn {
      justify-content: flex-end;

      .checkbox-btn__label {
        padding-right: 28px;
        padding-left: 0;
      }
    }
  }
}

div.job-details-field {
  .job-details-label {
    font-size: 14px;
    color: #646777;
    font-weight: 400;
    line-height: unset;
    margin: 0;
  }

  .job-details-description {
    font-size: 12px;
    color: #999999;
    line-height: 12px;
    margin: 0;
  }
}

p.job-note.typography-message {
  text-align: justify;
}

table.session-schedule-table {
  width: 100%;

  thead {
    color: #646777;
    background-color: #fafbfe;
  }

  th {
    color: #646777;
    max-width: 40px;
    min-width: 40px;
    font-weight: 400;

    .checkbox-btn__label {
      padding-left: 0;
    }

    &.label {
      max-width: unset;
      white-space: nowrap;
    }
  }
}

svg.info-icon {
  width: 20px;
  height: 20px;
  fill: #646777;
  margin-left: 5px;
  margin-bottom: 0.5rem;

  &:hover {
    fill: #4ce1b6;
    cursor: pointer;
  }
}

.locum-preview-modal {
  max-width: 805px;
  margin-top: 8%;

  .profile__information {
    padding: 0;
  }
}

.additional_work-modal {
  max-width: 624px !important;
  margin-top: 8%;
  .modal-content{
    width: 624px !important;
  }
}

.request-preview-modal{
  max-width: 80%;
  margin-top: 8%;

  .modal-content{
    width: 100%;
    border-radius: 6px;
    border: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 0px 30px 30px 30px;
    text-align: center;
    background: #FFFFFF;
    .modal__header{
      color: white;
      padding: 15px 30px;
      background: #333E48;
      width: 100% !important;
      height: 60px !important;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }


  .profile__information {
    padding: 0;
  }
}

h3.page-title {
  text-transform: unset;
}

.modal__body__message {
  color: #646777;
  padding-bottom: 15px;
  font-size: 14px;
}

.modal-dialog--primary {
  margin-top: 5%;
}

.modal-dialog--danger {
  margin-top: 7%;
}

.crop-image-modal {
  .modal-content {
    padding: 0 0 30px 0;
  }
}

.preview-image-modal {
  max-width: 100%;
}

.modal__title {
  color: #dddddd;
}

.upload-wrapper {
  position: relative;
  height: 140px;
  width: 140px;
}

.upload-image-dropzone-here {
  border: #646777 1px dashed;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  transition: 0.3s;

  svg {
    margin-left: -5px;
    margin-bottom: -5px;
  }

  &:hover {
    cursor: pointer;
    border-color: #4ce1b6;

    svg {
      fill: #4ce1b6;
    }
  }
}

.upload-image-dropzone {
  width: 110%;
  height: 110%;
  position: absolute;
  top: -5%;
  left: -5%;
  outline: none;
}


.bread-crumb-container {
  width: 100%;
  margin-bottom: 20px;
  font-size: 13px;
  line-height: 22px;
  text-transform: uppercase;

  .breadcrumb-item {
    color: #646777;

    &.active {
      color: #646777;
    }

    &.current {
      color: #4ce1b6;

      &:hover {
        color: #3aaa8a;
      }
    }

    &:hover {
      color: #4ce1b6;
    }
  }
}

.date-picker.date-picker--interval > div, .date-picker.date-picker--interval .react-datepicker-wrapper,
.date-picker.date-picker--interval .react-datepicker__input-container {
  max-width: unset;
}

.collapse-header {
  &:hover {
    cursor: pointer;
  }

  .collapse-icon {
    position: absolute;
    right: 0;
    top: 0;
    color: #646777;
  }
}

.user-documents {
  button.document-form-submit {
    white-space: nowrap;
    margin-left: 15px;
  }
}

.document-download-field {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .form__form-group-input-wrap {
    width: unset;
  }
}


.locum-document-table {
  font-size: 13px;
  width: 100%;

  td {
    padding: 5px;
  }

  .locum-document-label {
    color: #646777;
    font-weight: 500;
    line-height: unset;
  }


  .locum-document-file {
    display: flex;
    align-items: center;
    color: #646777;
    font-weight: 400;
    line-height: unset;

    svg {
      margin-right: 3px;
    }

    &:hover {
      cursor: pointer;
      color: #2a2a31;
    }
  }

  .locum-document-value {
    color: #646777;
    font-weight: 400;
    line-height: unset;
    min-height: 24px;
  }
}

.job-note {
  .job-note-title {
    font-size: 14px;
    color: #646777;
    font-weight: 400;
    line-height: unset;
    margin: 0;
  }

  .job-note-description {
    font-size: 13px;
    color: #999999;
  }
}


.locum-picture {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  user-select: none;

  .locum-letters {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #dddddd;

  }

  image {
    width: 100%;
    height: auto;
  }
}

.locum-name {
  .locum-type {
    color: #999999;
  }
}

.candidates {
  position: relative;
  min-height: 30px;

  &:hover {
    cursor: pointer;

  }

  .candidate-picture-wrapper {
    position: absolute;
    top: 0;
  }

  .candidate-picture {
    width: 30px;
    height: 30px;
    position: relative;
    border-radius: 50%;
    margin-right: 10px;
    overflow: hidden;
  }
}

.applicant {
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    color: #000000;
  }

  .applicant-picture-wrapper {
    position: relative;

    svg.mdi-icon {
      position: absolute;
      color: #5be694;
      width: 14px;
      height: 14px;
      right: 3px;
      bottom: -3px;
    }
  }

  .applicant-picture {
    width: 30px;
    height: 30px;
    position: relative;
    border-radius: 50%;
    margin-right: 10px;
    overflow: hidden;
  }
}

.table-checkbox {
  label {
    margin-bottom: 0;
    min-height: 18px;
  }
}

.sort-icon {
  svg {
    width: 20px;

    &:hover {
      color: #5be694;
      cursor: pointer;
    }
  }
}

.surgery_stamp {
  position: relative;

  .surgery_stamp_message {
    width: 100%;
    height: 150px;
    background-color: #f2f4f7;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: #646777;
  }

  .upload-image-dropzone {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    .upload-image-dropzone-here {
      border-radius: 5px;
      color: #646777;

      svg {
        margin: 5px;
      }

    }
  }
}

table.account-document-table {
  width: 100%;

  th.center {
    text-align: center;
  }

  td.account-document-value {
    position: relative;

    .radio-btn, .form__form-group-file label {
      margin-bottom: 0;
    }
  }

  .account-document-file {
    display: flex;
    align-items: center;
    color: #646777;
    font-weight: 400;
    line-height: unset;

    svg {
      margin-right: 3px;
    }

    &:hover {
      cursor: pointer;
      color: #2a2a31;
    }
  }


  td.account-document-status {

  }

  td.account-document-label {
    max-width: 140px;
    font-weight: bold;
  }

  td.account-document-expiration {
    max-width: 140px;
  }
}

.rating-button {
  &:hover {
    cursor: pointer;
    color: #4ce1b6;
  }
}

.modal-rating {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px 0;

  .rating-start {
    color: #646777;
    margin: 0 1px;

    svg {
      width: 27px;
      height: 27px;
    }

    &.start-hover {
      &:hover {
        cursor: pointer;
        color: #4ce1b6;
      }
    }
  }
}

.box-return-job{
  color: #646777;
  margin-bottom: 20px;

  .return-jobs{
    color: #646777;
    margin-right: 4px;
  }
  .right{
    margin-left: 4px;
  }
  .return-jobs:hover{
    color: #4ce1b6;
  }
}

.box-pagination{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
