.print_wrapper {
  width: 100vw !important;
  .print_header {
    width: 100%;
    padding: 17px 31px;
    background-color: #333e48;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .contact_header {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      p {
        font-size: 14px;
        color: #ffffff;
      }
    }
  }
  .body_wrapper {
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    .project_build_wrapper {
      display: flex;
      justify-content: space-between;
      .project_info {
        width: 70%;
      }
      .build_info {
        width: 30%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .analytic_wrapper {
      width: 100%;
      height: 46px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
      .analytic_item {
        width: 32%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .name {
          width: 80%;
          height: 100%;
          background: #f4f4f4;
          display: flex;
          align-items: center;
          padding: 12px 16px;

          font-weight: 500;
          font-size: 14px;
          color: #333e48;
        }
        .scope {
          width: 20%;
          height: 100%;
          background: #95a4b1;
          display: flex;
          align-items: center;
          justify-content: center;

          font-size: 14px;
          color: #ffffff;
        }
      }
    }
    .inspection_wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 32px;
      .inspection_information {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 13px 0;
        .inspection_item {
          width: 45%;
        }
      }
    }
  }
}

.question_card_wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 30px;
  .left_container {
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
    .question_info {
      margin-top: 8px;
    }
    .question_status {
      margin-top: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  .right_container {
    width: 100%;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    .image_container {
      width: 100%;
      display: flex;
      flex-direction: row;
      padding-right: 8px;
      img {
        width: 60px;
        height: 60px;
      }
    }
    .comment_container {
      width: 100%;
      margin-top: 12px;
      border-radius: 4px;
      background-color: #F4F4F4;
      padding: 8px;
      min-height: 90px;
      p {
        color: #333E48;
        font-weight: normal;
      }
    }
  }
  .img_wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    padding-right: 10px;
    img {
      width: 60px;
      height: 60px;
    }
  }
}

.dim {
  color: #95a4b1;
}

.question_card_wrapper {
  margin: 30px;
}

.daywork-print-wrapper {
  flex-wrap: wrap;
  flex: 0 0 100%;
  max-width: 100%;
}

.daywork-img-print {
  border-radius: 10px;
  width: 90px;
  height: 90px;
  margin-right: 14px;
}

.daywork-print-desc,
.daywork-print-details {
  max-width: 50%;
  flex: 0 0 50%;
}

.daywork-print-info {
  .info-inner {
    .dim {
      width: 50px;
      display: flex;
    }
  }
}

.gallery-divider {
  margin: 0;
  height: 1px;
  background-color: #ccc;
  border: none;
}

.print-photo-gallery {
  margin-left: -15px;
  margin-right: -15px;

  h5 {
    margin-left: 15px;
    margin-top: 30px;
    margin-bottom: 15px;
  }

  .img {
    width: 50%;
    float: left;
    padding: 15px;

    img {
      height: 450px;
      border-radius: 10px;
      object-fit: cover;
    }
  }
}

.print-btn {
  padding: 0.25rem 1.25rem;
  border-radius: 5px;
  background-color: #EDF3F8;
  font-size: 14px;
  display: inline-block;
}

.operatives-sm-img {
  object-fit: cover;
  border-radius: 10px;
}

.budget-print-details {
  span {
    min-width: 65px;
    display: inline-block;
  }

  .budget-value {
    font-size: 15px;
  }
}

// invoice print styling
.print_invoice_header{
  background-color: #333e48;
  width: 100vw;
  display: flex;
  align-items: center;
  padding:16px 32px;
  top:0;
  border-radius: 0.25rem 0.25rem 0 0;
  color:#fff;
}
.print_invoice_title{
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}
.print-invoice-long-content-body{
  position: relative;
  width: 100vw;
}
.print-invoice-short-content-body{
  position: relative;
  height: 90.75vh;
  width: 100vw;
}
