.rtl-support {
  .phone-input {
    direction: ltr;
    text-align: right;
  }
}

.error-input {
  border: 1px solid #ffbcbc !important;
}

.search_input {
  width: 400px;
  height: 46px;
  background: #f4f4f4;
  border-radius: 6px;
  position: relative;
  svg {
    position: absolute;
    top: 25%;
    left: 18px;
    width: 20px;
    height: 20px;
  }
  input {
    left: 0;
    width: 95% !important;
    height: 100% !important;
    background: transparent !important;
    padding: 0 0 0 50px !important;
    position: absolute !important;
    font-size: 14px !important;
    color: #333e48 !important;
    &:focus {
      border: none;
      outline: none;
    }
    &:active {
      border: none;
      outline: none;
    }
  }
}

.search_input-modal {
  width: 360px;
  height: 46px;
  background: #f4f4f4;
  border-radius: 6px;
  position: relative;
  svg {
    position: absolute;
    top: 25%;
    left: 18px;
    width: 20px;
    height: 20px;
  }
  input {
    left: 0;
    width: 95% !important;
    height: 100% !important;
    background: transparent !important;
    padding: 0 0 0 50px !important;
    position: absolute !important;
    font-size: 14px !important;
    color: #333e48 !important;
    &:focus {
      border: none;
      outline: none;
    }
    &:active {
      border: none;
      outline: none;
    }
  }
}

.create_user_field_wrapper {
  width: 48%;
  label {
    font-size: 14px;
  }
  input {
    font-size: 14px;
    color: black;
    width: 100%;
    background: transparent !important;
  }
  .MuiInputBase-root {
    width: 100%;
  }
}

.create_user_checkbox_wrapper {
  width: 48%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-size: 16px;
  justify-content: flex-start;
  line-height: 1.2;

  span {
    margin-right: 15px;
  }
}

.create_project_field_wrapper {
  width: 100%;
  label {
    font-size: 14px;
  }
  input {
    font-size: 14px;
    color: black;
    width: 100%;
    background: transparent !important;
  }
}

.MuiTextField-root {
  width: 100%;
}

.create_user_select_wrapper {
  width: 48.5%;
  .react-select {
    width: 100%;
    font-size: 14px;
    text-align: left;
    &:hover {
      cursor: pointer !important;
    }
  }
}
